import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { CaptureConsole, Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F9ae317d6e61f58f8a911c68597ca8b45@o4506191022194688.ingest.sentry.io\u002F4506195529891840",
    environment:"development",
    release:"1.0.11",
    ignoreErrors:["Can't find variable: _AutofillCallbackHandler","Abort due to cancellation of share.","Unexpected token '='","Non-Error exception captured with keys: currentTarget, isTrusted, target, type",""]
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver({types:["crash","intervention"]}),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}}),
    new CaptureConsole({levels:["error"]})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
